<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.report">
			<template #title>Benchmark Results</template>
			<template #title-actions-shown>

				<v-btn color="success" elevation="2" small @click="getData" :loading="loading">
					Get Data
				</v-btn>
			</template>
			<v-row>
				<v-col>
					<v-select v-model="report_params.corporate_id" :items="corporates" item-text="name" item-value="id" label="Corporate" clearable></v-select>
				</v-col>
				<v-col>
					<v-text-field type="date" v-model="report_params.start_date" label="Start date" clearable></v-text-field>
				</v-col>
				<v-col>
					<v-text-field type="date" v-model="report_params.end_date" label="End date" clearable></v-text-field>
				</v-col>
			</v-row>

			<v-data-table :headers="headers" :items="reportData.results" item-key="id" :search="search">
				<template v-slot:item.avg_score="{ item }">
					{{ Math.round(item.avg_score) }}
				</template>
				<template v-slot:footer.prepend>
					<v-row>
						<v-col cols="auto">
							<ti-export-to-csv :json-data="reportData.results" filename="Benchmark Results"/>
						</v-col>
						<v-col cols="auto">
							<v-btn class="primary--text" elevation="0" fab @click="pivot.show = !pivot.show" small>
								<v-icon>{{ $icons.pivot }}</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
		<ti-card :icon="$icons.pivot" v-if="pivot.show">
			<vue-pivottable-ui
				:data="reportData.results"
				:rows="pivot.rows"
				:cols="pivot.cols"
				aggregator-name="Integer Sum"
			>
			</vue-pivottable-ui>
		</ti-card>
	</v-container>
</template>

<script>

import {VuePivottableUi} from 'vue-pivottable'
import 'vue-pivottable/dist/vue-pivottable.css'
import {sync} from "vuex-pathify";

export default {
	name: "BenchmarkResult",
	components: {VuePivottableUi},
	computed: {
		corporates: sync('corporates/corporates'),
	},
	mounted() {
		this.init()
	},
	data: () => ({
		pivot: {
			show: false,
			rows: ['key_focus', 'industry', 'seniority'],
			cols: ['topic_name'],
		},
		report_params: {
			corporate_id: null,
			start_date: null,
			end_date: null,
		},
		search: '',
		loading: false,
		reportData: {
			count: 0,
			results: [
				{
					topic_name: '',
					industry: '',
					seniority: '',
					key_focus: '',
					avg_score: 0
				}
			]
		},
		headers: [
			{
				text: 'Topic',
				align: 'start',
				sortable: true,
				value: 'topic_name',
			},
			{
				text: 'Industry',
				align: 'start',
				sortable: true,
				value: 'industry',
			},
			{
				text: 'Seniority',
				align: 'start',
				sortable: true,
				value: 'seniority',
			},
			{
				text: 'Key Focus',
				align: 'start',
				sortable: true,
				value: 'key_focus',
			},
			{
				text: 'Avg Score',
				align: 'start',
				sortable: true,
				value: 'avg_score',
			},
		],
	}),
	methods: {
		init() {
			this.$store.set('corporates/getCorporates!', null);
		},
		getData() {
			this.loading = true;
			this.$api.reports.benchmarkResults({report_params: this.report_params})
				.then(response => this.reportData = response.data)
				.catch(error => console.log(error))
				.finally(() => this.loading = false)
		}
	}
}
</script>

<style lang="scss">
table.pvtTable thead tr th, table.pvtTable tbody tr th {
	background: #eee;
}

.pvtUi {
	* {
		font-family: 'roboto', sans-serif;
	}

	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;

	tr td {
		border: 1px solid #333;

		&:first-child {
			width: 300px;
		}
	}
}
</style>